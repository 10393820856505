import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import PageBuilder from '../components/contentTypes/pageBuilder'
import Category from '../templates/category'

class Pages extends Component {
  
  renderPageType = (pageBuilder, slug) => {
  
    /**
     * Set category slugs
     */
    const categories = [
      'case-studies',
      'testimonials'
    ]
  
    /**
     * Render category templete if slug is found
     * Else render Page template
     */ 
    if(categories.indexOf(slug) === -1) {
      return <PageBuilder data={pageBuilder} />
    } else {
      return <Category data={pageBuilder} slug={slug} />
    }
  }

  render() {
    const { headerHero, footerHero, pageBuilder, slug, seoTitle, seoDescription } = this.props.pageContext.data;
    return (
      <Layout>
        { <SEO title={seoTitle && seoTitle} description={seoDescription && seoDescription} /> }
        { headerHero && <Hero data={headerHero} /> }
        { this.renderPageType(pageBuilder, slug) }
        <div className="bottomhero">{ footerHero && <Hero data={footerHero} /> }</div>
      </Layout>
    )
  }
}

export default Pages